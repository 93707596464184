/* eslint-disable import/prefer-default-export */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const config = {
  apiKey: "AIzaSyDgMoe1B5w_AkU-4ZQnTdP1zF2y39H11Y4",
  authDomain: "kulshi.firebaseapp.com",
  projectId: "kulshi",
  storageBucket: "kulshi.appspot.com",
  messagingSenderId: "1085044970463",
  appId: "1:1085044970463:web:0f3e6431fc9eca596eaa6a",
  measurementId: "G-PRT31Q72S2",
};

const app = initializeApp(config);
export const authentication = getAuth(app);
