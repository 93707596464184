import getAgentInstance from './superagent'

export async function getRequest(url){
    const superagent = getAgentInstance()
    let data = await superagent.get(url)
    return data.body
}

export async function postRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.post(url).send(body)
    return data.body
}

export async function putRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.put(url).send(body)
    return data.body
}

export async function patchRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.patch(url).send(body)
    return data.body
}

export async function deleteRequest(url,body){
    const superagent = getAgentInstance()
    let data = await superagent.delete(url).send(body)
    return data.body
}
