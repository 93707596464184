/* eslint-disable max-len */
import React from 'react';
import {
    Row, Col, Form, Input, Button,
    message, Select, Card, Space, notification,
} from 'antd';
import {
    ArrowRightOutlined,
    LockFilled,
    UserOutlined,
} from '@ant-design/icons';
import Phone from './Phone.js'
import {observer, inject} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {RecaptchaVerifier, signInWithPhoneNumber} from 'firebase/auth';

import i18n from '../../i18n';
import logo from '../../assets/images/logo.svg';
import getAgentInstance from '../../helpers/superagent';
import {authentication} from '../../firebase/firebase';

import './login.css';

const {Option} = Select;
const superagent = getAgentInstance();

@inject('tokenStore', 'sectionsStore', 'userStore')
@observer
class Login extends React.Component {
    constructor() {
        super();
        this.initialState = () => ({
            loading: false,
            isUserValid: false,
            formValues: undefined,
            showPhone: true
        });
        this.state = this.initialState();

        // enerates a cptcha for our google firebase auth since it is mandatory!
        this.generateRecaptcha = () => {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                size: 'invisible',
                callback: () => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                },
            }, authentication);
        };

        this.handleFirebase = () => {
            const {formValues} = this.state;
            this.generateRecaptcha();
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(authentication, `+${formValues.phone_no}`, appVerifier)
                .then((res) => {
                    window.confirmationResult = res;
                    this.setState({isUserValid: true, loading: false});
                }).catch((err) => {
                // handle err
                notification.error({
                    message: 'An Error Occurred!',
                    description: `Something went wrong, ${err.message}`,
                    placement: 'bottomRight',
                });
                this.setState({loading: false});
            });
        };

        this.onSubmit = () => {
            this.setState({loading: true});
            const formValues = this.formRef.current.getFieldValue();
            const phoneNo = `964${formValues.phone_no.replace(/\s/g, '')}`;

            const firstData = {
                phone_no: parseInt(phoneNo, 10),
                client: 'lucid-dashboard',//formValues.client,
                passcode: formValues.passcode,
            };

            superagent
                .post(`${process.env.NEXT_PUBLIC_API_URL}/auth/first`)
                .send(firstData)
                .end((err) => {
                    if (!err) {
                        this.setState({
                            formValues: {
                                phone_no: phoneNo,
                                passcode: formValues.passcode,
                                client: formValues.client,
                            },
                        }, () => this.handleFirebase());
                    } else this.setState({loading: false});
                });
        };

        this.verifyOtp = () => {
            const otp = this.formRef.current.getFieldValue(['code']);
            this.setState({otpLoadingBtn: true});
            if (otp.length === 6) {
                const confirmOtp = window.confirmationResult;
                confirmOtp.confirm(otp).then((result) => {
                    // User signed in successfully.
                    const {user} = result;
                    this.onVerified(user.accessToken);
                }).catch(() => {
                    // User couldn't sign in (bad verification code?)
                    notification.error({
                        message: 'Invalid Input!',
                        description: 'Bad verification code was provided',
                        placement: 'bottomRight',
                    });
                    this.setState({otpLoadingBtn: false});
                });
            } else {
                // handle false entered values
                this.setState({otpLoadingBtn: false});
                notification.error({
                    message: 'Invalid Input!',
                    description: 'Input length should contain a 6 digit code!',
                    placement: 'bottomRight',
                });
            }
        };

        this.onVerified = (accessToken) => {
            const {formValues} = this.state;
            const otp = this.formRef.current.getFieldValue(['code']);
            const secondData = {
                phone_no: formValues.phone_no,
                passcode: formValues.passcode,
                id_token: accessToken,
                code: otp,
            };

            try {
                superagent
                    .post(`${process.env.NEXT_PUBLIC_API_URL}/auth/second`)
                    .send(secondData)
                    .end((err, res) => {
                        if (err) {
                            message.error({content: 'Login Failed!'});
                        } else {
                            const {body} = res;
                            console.log(body.data.token)
                            const {tokenStore, sectionsStore, userStore} = this.props;
                            if (body.success) {
                                window.location.reload();
                                userStore.value = body.data.user;
                                tokenStore.value = body.data.token;
                                sectionsStore.value = body.data.sections;

                                message.success({content: 'Successfully logged in'});
                            } else {
                                message.error({content: body.msg});
                            }
                        }
                        this.setState({loading: false});
                    });
            } catch (error) {
            } finally {
                this.setState({loading: false});
            }
        }

        this.onGoBack = () => {
            window.location.reload();
        };

        this.handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                this.onSubmit();
            }
        };

        this.handleVerifyOtpKeyDown = (event) => {
            if (event.key === 'Enter') {
                this.verifyOtp();
            }
        };

        this.formRef = React.createRef();

        this.onPhoneSent = () => {
            this.setState({
                showPhone: false
            })
        }

        // this function is for the reCapthca! when recaptcha request submits it'll cache in
        // so in order to remove that cache this function will reset it by unmounting the component!
        this.reMountPhoneComponent = () => {
            this.setState({
                showPhone: false
            })
            setTimeout(() => {
                this.setState({
                    showPhone: true
                })
            }, 100);
        }
    }

    componentDidMount() {
        if (i18n.language === 'en-US') {
            i18n.changeLanguage('en');
            window.location.reload();
        }
    }

    render() {
        const {loading, isUserValid, otpLoadingBtn, showPhone} = this.state;
        return (
            <>
                <Card className="login-card font-calss">
                    <div>
                        <Row justify="center" gutter={[0, 10]}>
                            <Col>
                                <p className="login-card-title font-calss">
                                    <img style={{width: '80%'}} src={logo} alt="logo"/>
                                </p>
                            </Col>
                        </Row>
                        <Row justify="center" gutter={[0, 64]}>
                            <Col style={{textAlign: 'center'}}>
                                <p className="login-card-subtitle font-calss">
                                    قم بتسجيل الدخول
                                </p>
                                <p className="font-calss">
                                    بەڕێوەبەردنی ئۆفیسەکانتان
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {showPhone && <Phone
                                    onPhoneSent={this.onPhoneSent}
                                    reMountPhoneComponent={this.reMountPhoneComponent}
                                />}
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <p className="login-card-subtitle font-calss" style={{fontSize: 12}}>
                                    Kulshi
                                    {' '}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://lucid-source.com"
                                        style={{fontFamily: 'Cuprumsemi'}}
                                    >
                                        <span style={{fontSize: 16}}>{i18n.t('Lucid Source')}</span>
                                    </a>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Card>
                <div id="recaptcha-container"/>
            </>
        );
    }
}

export default withTranslation()(Login);
