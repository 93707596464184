import _ from 'lodash'
import Swal from 'sweetalert2'
import superagent from 'superagent'
import React, {useState} from 'react'
import {RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth"
import {Form, Input, Button, Row, Col} from 'antd'

import Otp from './Otp'
import {authentication} from '../../firebase/firebase'
import userIcon from '../../assets/images/icons/user.svg'

export default function Phone({reMountPhoneComponent}) {

    const [number, setNumber] = useState()
    const [showOtp, setShowOtp] = useState(false)
    const [showPhone, setShowPhone] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [otpLoading, setOtpLoading] = useState(false)
    const [firebaseToken, setFirebaseToken] = useState()
    const [enableLogin, setEnableLogin] = useState(false)

    const onPhoneNumberChange = _.debounce((e) => {
        const phone = formRef.current.getFieldValue('phone')
        let removeSpace = phone.replaceAll(' ', '')
        if (removeSpace.startsWith('0')) {
            removeSpace = removeSpace.substring(1)
        }
        if (!removeSpace.startsWith('+964') && !removeSpace.startsWith('964')) {
            removeSpace = `+964${removeSpace}`
        }
        const lengthNumber = removeSpace.substring(1).length
        console.log(lengthNumber)
        console.log(removeSpace)
        if (lengthNumber === 13) {
            setNumber(removeSpace)
            setEnableLogin(true)
        } else setEnableLogin(false)
    }, 300)

    const onSubmit = (values) => {
        superagent
            .post(`${process.env.REACT_APP_API_URL}/admin/auth/verify`)
            .send({phone_no: number.replace('+','')})
            .set("Content-Type", "application/json")
            .type("json")
            .end((err, res) => {
                const {status} = res
                console.log({err,res})
                // checks if the user needs to sign up
                if (status === 200) {
                    Swal.fire({title: 'Not registered or wrong phone number', icon: 'error'})
                } else {
                    // user exists and should send the OTP code
                  if(status === 403)
                    handleFirebase()
                }
            })
    }

    const formRef = React.createRef();

    // generates a cptcha for our google firebase auth since it is mandatory!
    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': () => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, authentication);
    }

    const handleFirebase = () => {
        setIsLoading(true)
        generateRecaptcha()
        let appVerifier = window.recaptchaVerifier
        signInWithPhoneNumber(authentication, number, appVerifier)
            .then((res) => {
                setShowPhone(false)
                setShowOtp(true)
                setIsLoading(false)
                window.confirmationResult = res
            }).catch((err) => {
            // handle err
            setIsLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'An Error Occurred!',
                html: `Something went wrong <br /> ${err.message}`,
            }).then((res) => {
                if (res.isConfirmed) reMountPhoneComponent()
            })
        })
    }

    const verifyOtp = (code) => {
        setOtpLoading(true)
        if (code.length === 6) {
            let confirmOtp = window.confirmationResult
            confirmOtp.confirm(code).then((result) => {
                // User signed in successfully.
                const user = result.user
                const data = {
                    accessToken: user.accessToken,
                    phoneNumber: user.phoneNumber,
                }
                setFirebaseToken(user.accessToken)
                shouldRegister(data)
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                Swal.fire({
                    icon: 'error',
                    title: 'An Error Occurred!',
                    html: `Something went wrong <br /> ${error.message}`,
                });
            });
        }
    }

    const shouldRegister = (val) => {
        superagent
            .post(`${process.env.REACT_APP_API_URL}/admin/auth/verify`)
            .send({phone_no: val.phoneNumber.replace(/\D/g, '')})
            .set("Content-Type", "application/json")
            .type("json")
            .end((err, res) => {
                const {status} = res
                // checks if the user needs to sign up
                if (status === 200) {
                    // needs to sign up
                    setShowOtp(false)
                    setOtpLoading(false)
                } else {
                    // user exists and should request for the user data
                    onLoginUser(val.accessToken);
                }
            });
    };

    const onLoginUser = (fbToken) => {
        const data = {
            phone_no: number.slice(1),
            id_token: fbToken,
        }

        superagent
            .post(`${process.env.REACT_APP_API_URL}/admin/auth/login`)
            .send(data)
            .set("Content-Type", "application/json")
            .type("json")
            .end((err, res) => {
                if (!err) {
                    const {token} = res.body;
                    localStorage.setItem(process.env.REACT_APP_TOKEN_LOCALSTORAGE_NAME, token);
                    window.location.reload()
                }
                setOtpLoading(false)
            });
    }

    const handleReset = () => {
        setShowPhone(true)
        setShowOtp(false)
    }

    return (
        <>
            {showPhone && (
                <div>
                    <Form onFinish={onSubmit} ref={formRef}>
                        <Row gutter={10}>
                            <Col span={24}>
                                <Form.Item
                                    name="phone"
                                    label={'Phone number'}
                                    autoComplete="off"
                                    rules={[{
                                        required: true,
                                    }]}
                                    className="Input_with_addBefores"
                                >
                                    <Input
                                        id={'phone'}
                                        placeholder="ژمارەی مۆبایل / رقم الهاتف (075x xxx xxxx)"
                                        onChange={onPhoneNumberChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Button
                                type="primary"
                                htmlType={'submit'}
                                disabled={!enableLogin}
                            >
                                {isLoading ? 'loading...' : 'Send'}
                            </Button>
                        </Row>
                    </Form>
                    <div id="recaptcha-container"></div>
                </div>
            )}
            {showOtp && (
                <Otp
                    verifyOtp={verifyOtp}
                    verifying={otpLoading}
                    handleReset={handleReset}
                />
            )}
        </>
    )
}
