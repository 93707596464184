import {lazy} from 'react';

import {
    BarsOutlined,
    DeploymentUnitOutlined,
    ToolOutlined,
    InboxOutlined,
    LaptopOutlined,
    DesktopOutlined,
    CameraOutlined,
    TagOutlined,
    GatewayOutlined,
    OrderedListOutlined,
    SettingOutlined,
    UserSwitchOutlined,
    QuestionOutlined,
    CopyOutlined,
    DashboardOutlined,
    AliyunOutlined,
    EnvironmentOutlined,
    HomeOutlined,
    QrcodeOutlined
} from '@ant-design/icons'

const options = [
    {
        key: Math.random(),
        path: '/',
        component: lazy(() => import('../dashboard/Content')),
        exact: true,
        name: 'Dashboard',
        icon: BarsOutlined,
        render: true
    },
    {
        key: Math.random(),
        exact: true,
        name: 'Home Page',
        render: true,
        icon: HomeOutlined,
        subRoutes: [
            {
                key: Math.random(),
                path: '/Categories',
                component: lazy(() => import('../categories/List')),
                exact: true,
                name: 'Categories',
                icon: OrderedListOutlined,
                render: true,
            },
            {
                key: Math.random(),
                path: '/Banners',
                component: lazy(() => import('../banners/List')),
                exact: true,
                name: 'Banners',
                icon: GatewayOutlined,
                render: true
            },
        ]
    },
    {
        key: Math.random(),
        exact: true,
        name: 'Product Information',
        render: true,
        icon: TagOutlined,
        subRoutes: [
            {
                key: Math.random(),
                path: '/Brands',
                component: lazy(() => import('../brands/List')),
                exact: true,
                name: 'Brands',
                icon: DeploymentUnitOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/Models',
                component: lazy(() => import('../models/List')),
                exact: true,
                name: 'Models',
                icon: DeploymentUnitOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/products',
                name: 'Products',
                component: lazy(() => import('../products/List')),
                exact: true,
                icon: TagOutlined,
                render: true
            },
        ]
    },
    {
        key: Math.random(),
        exact: true,
        name: 'Setting',
        render: true,
        icon: SettingOutlined,
        subRoutes: [
            {
                key: Math.random(),
                exact: true,
                name: 'General Setting',
                render: true,
                icon: SettingOutlined,
                subRoutes: [
                    {
                        key: Math.random(),
                        path: '/Settings',
                        component: lazy(() => import('../applicationVariables/List')),
                        exact: true,
                        name: 'Legl & info',
                        icon: SettingOutlined,
                        render: true
                    },
                    {
                        key: Math.random(),
                        path: '/CustomerService',
                        component: lazy(() => import('../customerService/List')),
                        exact: true,
                        name: 'Customer Service',
                        icon: UserSwitchOutlined,
                        render: true
                    },
                    {
                        key: Math.random(),
                        path: '/FAQ',
                        component: lazy(() => import('../FAQ/List')),
                        exact: true,
                        name: 'FAQ',
                        icon: QuestionOutlined,
                        render: true
                    },
                ]
            },
            {
                key: Math.random(),
                exact: true,
                name: 'Tech Component',
                render: true,
                icon: LaptopOutlined,
                subRoutes: [
                    {
                        key: Math.random(),
                        exact: true,
                        name: 'Hardware',
                        render: true,
                        icon: LaptopOutlined,
                        subRoutes: [
                            {
                                key: Math.random(),
                                path: '/CPUs',
                                component: lazy(() => import('../cpus/List')),
                                exact: true,
                                name: 'CPUs',
                                icon: ToolOutlined,
                                render: true
                            },
                            {
                                key: Math.random(),
                                path: '/GPUs',
                                component: lazy(() => import('../gpus/List')),
                                exact: true,
                                name: 'GPUs',
                                icon: ToolOutlined,
                                render: true
                            },
                            {
                                key: Math.random(),
                                path: '/RAMsTypes',
                                component: lazy(() => import('../ramTypes/List')),
                                exact: true,
                                name: 'RAMs Types',
                                icon: ToolOutlined,
                                render: true
                            },
                            {
                                key: Math.random(),
                                path: '/RAMs',
                                component: lazy(() => import('../ram/List')),
                                exact: true,
                                name: 'RAMs',
                                icon: ToolOutlined,
                                render: true
                            },
                        ]
                    },
                    {
                        key: Math.random(),
                        exact: true,
                        name: 'Storages',
                        render: true,
                        icon: InboxOutlined,
                        subRoutes: [
                            {
                                key: Math.random(),
                                path: '/StorageTypes',
                                component: lazy(() => import('../storageTypes/List')),
                                exact: true,
                                name: 'Storage Types',
                                icon: InboxOutlined,
                                render: true
                            },
                            {
                                key: Math.random(),
                                path: '/StorageSizes',
                                component: lazy(() => import('../storageSizes/List')),
                                exact: true,
                                name: 'Storage Sizes',
                                icon: InboxOutlined,
                                render: true
                            },
                            {
                                key: Math.random(),
                                path: '/internalStorage',
                                component: lazy(() => import('../internalStorage/List')),
                                exact: true,
                                name: 'Internal Storage',
                                icon: InboxOutlined,
                                render: true
                            }
                        ]
                    },
                ]
            },
            {
                key: Math.random(),
                path: '/Screens',
                component: lazy(() => import('../screenSizes/List')),
                exact: true,
                name: 'Screens',
                icon: DesktopOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/Cameras',
                component: lazy(() => import('../camera/List')),
                exact: true,
                name: 'Cameras',
                icon: CameraOutlined,
                render: true
            },
        ]
    },
    {
        key: Math.random(),
        exact: true,
        name: 'SEO',
        render: true,
        icon: DashboardOutlined,
        subRoutes: [
            {
                key: Math.random(),
                path: '/Pages',
                component: lazy(() => import('../page/List')),
                exact: true,
                name: 'Pages',
                icon: CopyOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/Metas',
                component: lazy(() => import('../metas/List')),
                exact: true,
                name: 'Metas',
                icon: AliyunOutlined,
                render: true
            }
        ]
    },
    {
        key: Math.random(),
        exact: true,
        name: 'Special Offers',
        render: true,
        icon: TagOutlined,
        subRoutes: [
            {
                key: Math.random(),
                path: '/Booths',
                component: lazy(() => import('../boothes/List')),
                exact: true,
                name: 'Booths',
                icon: EnvironmentOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/SpecialOfferProducts',
                name: 'Products',
                component: lazy(() => import('../specialOfferProducts/List')),
                exact: true,
                icon: TagOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/SpecialOfferBooks',
                name: 'Books',
                component: lazy(() => import('../books/List')),
                exact: true,
                icon: QrcodeOutlined,
                render: true
            },
        ]
    },
    {
        key: Math.random(),
        path: '/auctions',
        name: 'Auctions',
        component: lazy(() => import('../auctions/List')),
        exact: true,
        icon: TagOutlined,
        render: true
    },
    {
        key: Math.random(),
        path: '/my-profile',
        component: lazy(() => import('../my-profile/Main')),
        exact: true,
        render: false
    },
    {
        key: Math.random(),
        path: '/user-profile',
        component: lazy(() => import('../user-profile/List')),
        exact: true,
        render: false
    }
];
export default options;
