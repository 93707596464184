import Countdown from 'react-countdown'
import React, { useEffect } from 'react'

export default function Otp({ handleReset, verifyOtp, verifying }) {

  useEffect(() => {
    const inputElements = [...document.querySelectorAll('input.code-input')]

    inputElements.forEach((ele, index) => {
      ele.addEventListener('keydown', (e) => {
        if (e.keyCode === 8 && e.target.value === '') inputElements[Math.max(0, index - 1)].focus()
      })
      ele.addEventListener('input', (e) => {
        const [first, ...rest] = e.target.value
        e.target.value = first ?? ''
        if (index !== inputElements.length - 1 && first !== undefined) {
          inputElements[index + 1].focus()
          inputElements[index + 1].value = rest.join('')
          inputElements[index + 1].dispatchEvent(new Event('input'))
        }
      })
    })
  }, [])

  function onSubmit(e) {
    e.preventDefault()
    const otpCode = [...document.getElementsByClassName('code-input')]
      .filter(({ name }) => name)
      .map(({ value }) => value)
      .join('')
    verifyOtp(otpCode)
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span
        style={{ cursor: 'pointer' }}
        onClick={() => handleReset()}
      >
        Re-Send Code
      </span>;
    } else {
      return (
        <span>
          Re-Send Code In {minutes}:{seconds}
        </span>
      );
    }
  };


  return (
    <div>
      <h3>Vertification</h3>
      <form className="otp_form_login">
        <div>
          <input name='code' className="code-input" autoComplete="off" required />
          <input name='code' className="code-input" autoComplete="off" required />
          <input name='code' className="code-input" autoComplete="off" required />
          <input name='code' className="code-input" autoComplete="off" required />
          <input name='code' className="code-input" autoComplete="off" required />
          <input name='code' className="code-input" autoComplete="off" required />
        </div>
        <input type="submit" value={verifying ? "verifying...": "Verify"} onClick={onSubmit} />
      </form>
      <div className="resend_otp">
        <Countdown date={Date.now() + (60 * 1000)} renderer={renderer} />
      </div>
    </div>
  )
}
